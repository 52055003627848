body {
  font-family: "Lato", sans-serif;
  color: #444444;
  overflow-x: hidden;
}

a {
  color: #980033;
  text-decoration: none;
}

a:hover {
  color: #980033;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}

h3 {
  font-size: 54px;
  font-weight: 800;
}

p {
  font-size: 15px;
}
#home {
  /*background-color: #980033;*/
  background-repeat: no-repeat;
  background-size: cover;
  /*height:650px;*/
}
#home .middle-sec {
  background-color: #980033;
  height: 439px;
}

#home .bgImage {
  background-image: url("../img/main banner fish image (2).png");
  /* background-image: url('../img/main\ banner.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 439px;
}

.alignLeft {
  padding-left: 103px;
}
.alignRight {
  padding-right: 103px;
}
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/

/* #preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;
}

#preloader:before {
	content: "";
	position: fixed;
	top: calc(50% - 30px);
	left: calc(50% - 30px);
	border: 6px solid #980033;
	border-top-color: #e2eefd;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	-webkit-animation: animate-preloader 1s linear infinite;
	animation: animate-preloader 1s linear infinite;
} */

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.italic {
  padding: 20px 0px;
}

.italic i {
  font-size: 15px;
  font-weight: 600;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/

.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #000;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
/*.navbar{*/
/*        justify-content: center !important;*/
/*}*/
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #980033;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  .alignLeft {
    padding-left: 0px !important;
  }

  #home .middle-sec h3 {
    font-size: 25px !important;
    padding: 0px 20px;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

#header {
  background: #fff;
  transition: all 0.5s;
  z-index: 997;
  height: 86px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header.fixed-top {
  height: 70px;
  position: inherit;
}

#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 15px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.8px;
  font-family: "Poppins", sans-serif;
}

#header .logo a {
  color: #222222;
}

#header .logo a span {
  color: #980033;
}

#header .logo img {
  max-height: 40px;
}

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}
/* .navbar li button{
    border:1px solid #980033;
} */
.navbar > ul > li {
  white-space: nowrap;
  padding: 10px 0 10px 20px;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3px;
  font-size: 14px;
  font-weight: 600;
  color: #222222;
  white-space: nowrap;
  transition: 0.3s;
  position: relative;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #980033;
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.navbar a:hover:before,
.navbar li:hover > a:before,
.navbar .active:before {
  visibility: visible;
  width: 100%;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #980033;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 28px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-weight: 400;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #980033;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/

.mobile-nav-toggle {
  color: #222222;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #222222;
}

.navbar-mobile > ul > li {
  padding: 0;
}

.navbar-mobile a:hover:before,
.navbar-mobile li:hover > a:before,
.navbar-mobile .active:before {
  visibility: hidden;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #980033;
}

.navbar-mobile .getstarted {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #980033;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

#hero {
  width: 100%;
  background: #f9f9f9;
  position: relative;
}

#hero .container {
  position: relative;
}

@media (max-width: 768px) {
  /*#hero {*/
  /*  height: 100vh;*/
  /*}*/
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .btn-get-started,
  #hero .btn-watch-video {
    font-size: 13px;
  }
}

section.privacyPolicy h6 {
  font-size: 20px;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: 8px 20px;
  margin: 0;
  background: #e7f1fd;
  color: #980033;
  display: inline-block;
  text-transform: uppercase;
  border-radius: 50px;
}

.section-title h3 span {
  color: #980033;
}

.section-title p {
  margin: 15px auto 0 auto;
  font-weight: 600;
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}

/*--------------------------------------------------------------
# Featured Services
--------------------------------------------------------------*/

#home {
  padding: 0px !important;
}

.featured-services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}

.featured-services .icon {
  margin-bottom: 15px;
}

.featured-services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.featured-services .title a {
  color: #111;
}

.featured-services .description {
  font-size: 15px;
  margin-bottom: 0;
}

.about .content ul p {
  font-size: 15px;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.order-now .icon-box {
  text-align: center;
}

.order-now .icon-box p {
  margin: auto;
  width: 300px;
  font-size: 15px;
  font-weight: 500;
}

.order-now .icon-box .icon {
  height: 350px;
}

.span.toolTip-Menu {
  position: absolute;
  top: -10px;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 5px;
  color: #fff;
  border-radius: 7px;
  left: 60px;
  background: #980033;
}
#home .middle-sec h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 20px;
}

/*--------------------------------------------------------------

# Counts
--------------------------------------------------------------*/
#awords {
  background: #212529;
}
.seller-platform .seller-info {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 0 29px 0 rgb(68 88 144 / 12%);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
  padding: 12px;
  font-size: 15px;
  height: 180px;
  margin: 50px 0px;
  position: relative;
}

.seller-platform {
  padding: 60px 0px 0px;
  background-color: #f9f9f9;
}

.seller-platform .seller-info img {
  width: 38%;
  position: absolute;
  top: -30px;
}

.seller-platform .seller-info p {
  padding: 30px 15px 20px 20px;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .seller-platform .seller-info p {
    padding: 30px 15px 20px 20px;
  }
  .seller-platform .seller-info img {
    width: 25%;
  }
  /* .seller-platform .seller-info {
    width: 140px;
  } */
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .seller-platform .seller-info {
    margin-bottom: 20px;
    background: #fff;
    box-shadow: 0 0 29px 0 rgb(68 88 144 / 12%);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    padding: 12px;
    font-size: 15px;
    height: 207px;
    margin: 50px 0px;
    position: relative;
    width: 159px;
  }
}

.seller-platform .fixing-img {
  position: relative;
  left: 116px;
}

.lines {
  width: 120px;
  height: 10px;
  background-color: #980033;
  margin: auto;
}

.owl-item {
  width: 128.906px;
  margin-right: 10px;
}
/*--------------------------------------------------------------
# Clients
--------------------------------------------------------------*/

.clients {
  padding: 30px 0;
  background-color: #f9f9f9;
  text-align: center;
}
.clients .col {
  max-width: 16%;
  flex: 16%;
}

.clients img {
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
}

.clients img:hover {
  transform: scale(1.15);
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #fff;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  padding: 60px 0 0px 0;
  background: #212529;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 span {
  color: #980033;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #980033;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links .float-right {
  background-color: #980033;
  padding: 10px 10px;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 10px;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
  padding: 10px 0px;
}

#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #980033;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #980033;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #980033;
  color: #fff;
  text-decoration: none;
}

#footer .copyright {
  text-align: left;
  color: #000;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #444444;
}

.mobile-view {
  display: none !important;
}

.slider {
  background: #212529;

  text-align: center;
}

.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 70s linear infinite;
  display: flex;
  width: calc(250px * 18);
  background-color: #fff;
}

.slider .slide-track:hover {
  animation-play-state: auto;
}

.slider .slide {
  height: 250px;
  padding: 0px 20px;
}
.caption {
  color: #000 !important ;
}
.form_section {
  background-image: url("../img/Group\ 2151\ 1.png");
  background-repeat: no-repeat, repeat;
  padding: 0px;
}
.social-links {
  position: relative;
  background: #980033;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  width: 445px;
  margin: auto;
}
.social-links span {
  font-weight: 700;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}

@media (max-width: 768px) {
  #features .lines.m-0,
  #seller .lines.m-0,
  #gallery .lines.m-0 {
    margin: auto !important;
  }
  #features .italic,
  #seller .italic {
    text-align: center;
    padding: 20px;
  }
  #gallery .slider .slide-track {
    margin: 20px 0px;
  }
  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: left;
    padding: 2px 0;
  }
  #transporter .italic,
  #seller .italic {
    margin: 0px 20px;
  }
  #freshr-product .col-lg-4.col-md-6.d-flex,
  #order-now .col-lg-4.col-md-6.d-flex {
    justify-content: center;
  }
  #gallery span {
    width: 100%;
  }
  h3 {
    font-size: 30px;
    text-align: center;
    font-weight: 800;
  }
  .seller-platform .seller-info img {
    width: 30%;
  }
  .seller-platform .col-lg-3 {
    max-width: 50%;
  }
  .seller-platform .seller-info {
    margin: 25px 0px;
  }
  #transporter .div-six {
    padding: 30px 30px;
  }
  #transporter .w-50 {
    width: 100% !important;
  }
  .seller-platform .fixing-img {
    position: relative;
    left: auto;
  }
  .mobile-view {
    display: block !important;
  }
  #gallery .lightbox img {
    width: 100%;
    margin: 10px 0px;
  }
  .desktop-view {
    display: none !important;
  }
  .featured-services .col-md-4 {
    max-width: 50%;
  }
  .featured-services .icon-box {
    width: 100%;
    padding: 12px;
    margin-bottom: 0px;
  }
  #home {
    padding: 0px 0px 0px !important;
  }
  #home .middle-sec {
    text-align: center;
  }
  #home .middle-sec button {
    margin-bottom: 40px;
  }
  .middle-sec {
    justify-content: center;
  }
  section#order-now {
    padding-top: 0px;
  }
  section#transporter {
    padding-bottom: 0px;
  }
  section#features {
    padding: 0px;
  }
  .footer-top .mt-5 {
    margin-top: 10px !important;
  }
  .clients .col {
    max-width: 50%;
    flex: 50%;
  }
  .caption {
    display: block;
    width: 100%;
  }
  .social-links {
    width: auto;
  }
  #footer .footer-top {
    padding: 30px 0px;
  }
}

@media (max-width: 600px) {
  section#transporter .col-md-2.col-sm-2 {
    max-width: 20%;
  }
  #transporter .w-50 {
    width: 80% !important;
  }
  #awords .col {
    max-width: 50%;
  }
}
@media (min-width: 992px) and (max-width: 1282px) {
  .seller-platform .fixing-img {
    left: 0;
  }
}
@media (min-width: 768px) and (max-width: 957px) {
  .seller-platform .fixing-img {
    left: 0;
  }
}
