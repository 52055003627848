/*common css*/
a:hover,
a:focus {
  color: #980033;
}

.text-right {
  text-align: right;
}

/* ashish's code */
.hide {
  display: none;
}

.footerLinks {
  color: white;
  text-decoration: none;
  background: none;
}

.footerLinks:hover {
  color: white !important;
}

/* end */
.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.pdt-0 {
  padding-top: 0px !important;
}

.pdtb-40 {
  padding: 40px 0px;
}

.pdrl-15 {
  padding: 0px 15px;
}

.pdrl-25 {
  padding: 0px 25px;
}

h2 {
  font-size: 32px;
  font-weight: 900;
}

.noOpacity {
  opacity: 0;
}

.opacity {
  opacity: 1;
}

.ratingStartDiv {
  fill: #fff;
  stroke: #5e5e5e;
}

.ratingStarDiv1 svg:first-child {
  fill: #5e5e5e;
}

.ratingStarDiv2 svg:first-child,
.ratingStarDiv2 svg:nth-child(2) {
  fill: #5e5e5e;
}

.ratingStarDiv3 svg:first-child,
.ratingStarDiv3 svg:nth-child(2),
.ratingStarDiv3 svg:nth-child(3) {
  fill: #5e5e5e;
}

.ratingStarDiv4 svg:first-child,
.ratingStarDiv4 svg:nth-child(2),
.ratingStarDiv4 svg:nth-child(3),
.ratingStarDiv4 svg:nth-child(4) {
  fill: #5e5e5e;
}

.ratingStarDiv5 svg:first-child,
.ratingStarDiv5 svg:nth-child(2),
.ratingStarDiv5 svg:nth-child(3),
.ratingStarDiv5 svg:nth-child(4),
.ratingStarDiv5 svg:nth-child(5) {
  fill: #5e5e5e;
}

.aboutInnerDiv {
  padding: 40px;
  text-align: center;
}

.aboutInnerDiv img {
  width: 80%;
  display: table;
  margin: 0 auto;
}

.aboutInnerDiv .aboutInnerImageDiv {
  width: 350px;
  height: 290px;
}

/*header css*/
.headerSec .collapse {
  height: 100%;
  padding-bottom: 10px;
}

.headerSec form .mb-3 {
  margin-bottom: 0px !important;
}

.Headerbutton {
  background: transparent;
  color: #333;
  border-color: #eee;
  padding: 6px 25px;
}

/* .nav {
  border-bottom: 2px solid #b3b3b3;
} */
.nav {
  height: 100%;
  border: 0px;
}

.mainHeaderDiv {
  border-bottom: 2px solid #b3b3b3;
  padding-bottom: 15px;
}

.nav-link {
  /* color: #bfbfbf; */
  color: #212529;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-size: 20px;
}

.nav-link.active,
.nav-link:hover,
.nav-link:focus {
  color: #980033;
  cursor: pointer;
}

/*home page*/
.mainContent {
  /* padding: 40px 25px; */
  padding: 25px 0px;
}

.categoryImageSection {
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
}

.categoryImageSection img {
  width: 100%;
}

.categoryImageSection .categoryNameDiv {
  position: absolute;
  bottom: 0px;
  left: 25px;
  /* color: #fff; */
  font-weight: 600;
  font-size: 20px;
}

.carousel-content.show-3 > * {
  width: calc(100% / 3);
}

.CarouseDivTwoContainer,
.CarouseDivTwo,
.CarouseDivTwo .card-body {
  background: #fbfbfb;
}

.CarouseDivTwo h4 {
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 400;
}

.carouselTowHeader {
  font-size: 26px;
  font-weight: 900;
  margin-bottom: 0px;
}

.carouselTowHeader span {
  font-weight: 400;
}

.CarouseDivTwo {
  border: 0px;
  margin: 15px 0px;
  border: #000000 solid 1px;
  /* #00c50d solid 1px; */
  border-radius: 3px;
  padding: 1px 3px;
}

span.arrow-sku {
  padding: 3px;
}

.CarouseDivOne .CarouseDivTwo .card-body {
  margin: 0px 15px;
  padding: 0px;
  border-right: 0.5px solid #5e5e5e;
}

.CarouseDivTwo CardText,
.CarouseDivTwo p {
  font-size: 26px;
  font-weight: 400 !important;
}

.CarouseDivTwo p:last-child {
  margin-top: 10px;
}

/* .CarouseDivTwo p:last-child.belowValue {
  color: #ff1a00;
}

.CarouseDivTwo p:last-child.belowValue span svg {
  fill: #ff1a00;
}

.CarouseDivTwo p:last-child.aboveValue {
  color: #00c50d;
}

.CarouseDivTwo p:last-child.aboveValue span svg {
  fill: #00c50d;
  transform: rotate(180deg);
} */
.CarouseDivOne {
  color: #625d5d;
  margin: 15px 0;
  /* #ff1a00; */
  border: #625d5d solid 1px;
  /* #ff1a00 solid 1px; */
  border-radius: 3px;
  padding: 1px 3px;
}

.CarouseDivOneSvg {
  fill: #625d5d;
  /* #ff1a00; */
}

.CarouseDivTwo {
  color: #000000;
  /* #00c50d; */
}

.CarouseDivTwoSvg {
  fill: #000000;
  /* #00c50d; */
  transform: rotate(180deg);
}

.CarouseDivTwo p span {
  padding-left: 30px;
}

.mainContent.pbottom40.productsContent {
  /* background: url("./images/getintouchbanner.png"); */
  background-position: center;
  background-size: cover;
}

/* .homeLoginDivCss .homeLogLeft {
  background: url("./images/getintouchbanner.png");
  background-position: center;
  background-size: cover;
  color: #fff;
} */
#freshr-product {
  /* background: url("./assets/img/Group 4447 1.png"); */
  background-position: center;
  background-size: cover;
}

.formBackimg {
  background: url("/src/assets/img/Group 2151 1.png");
  background-position: center;
  background-size: cover;
  color: #fff;
  padding: 0px;
  margin-top: 60px;
}

.formBackimg .homeLoginDivCss .homeLogLeft h4 {
  font-weight: 900;
  font-size: 41px;
  line-height: 48px;
  color: #ffffff;
}

.formBackimg .homeLoginDivCss .homeLogLeft i {
  font-style: italic;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #ffffff;
}

.formBackimg .homeLoginDivCss .homeLogLeft {
  padding: 50px 0px 20px;
}

/* .homeLoginDivCss .homeLogRight{
  padding: 40px 150px;
} */
.formBackimg .homeLoginDivCss .homeLogRight .redColor {
  font-weight: 900;
  font-size: 25px;
  line-height: 29px;

  color: #980033;
}

.formBackimg .homeLoginDivCss .homeLogRight .blackColor {
  font-style: normal;
  font-weight: 900;
  font-size: 31px;
  line-height: 36px;
  text-align: center;
  color: #212529;
}

.formBackimg .homeLoginDivCss .homeLogRight .fs-21 {
  font-style: italic;
  font-weight: bold;
  font-size: 21px;
  margin-top: 20px;
  line-height: 25px;
  /* identical to box height */

  color: #212529;
}

.exploreProduct a:hover {
  color: #FFFF !important;
}

.formBackimg .homeLoginDivCss .homeLogRight p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #212529;
}

.formBackimg .homeLoginDivCss .homeLogLeft .italicText {
  margin-top: 20px;
}

.formBackimg .homeLoginDivCss .homeLogLeft p {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
}

.homeLoginDivCss .homeLogRight {
  background: #f9f9f9;
  padding: 70px 115px 0px 115px;
  text-align: center;
  position: absolute;
  width: 593px;
  height: 540px;
  right: 81px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .homeLoginDivCss .homeLogRight {
    background: #f9f9f9;
    padding: 50px;
    text-align: center;
    position: absolute;
    width: 446px;
    height: 484px;
    right: 81px;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  .homeLoginDivCss .homeLogRight {
    position: inherit;
    padding: 40px 20px;
    height: auto;
    width: 85%;
    margin: auto;
  }
}
/* 765 se 575 */
@media (min-width: 575px) and (max-width: 765px) {
  .responsiveFix {
    margin: auto;
  }
}

.formBackimg .homeLoginDivCss {
  align-items: end;
}

.homeLoginDivCss input {
  text-align: center;
  width: 70%;
  margin: 0 auto;
}

.homeLoginDivCss button {
  padding: 5px 40px;
  border-radius: 0px;
}

/*products page*/
.productsContent .form-select,
.productsContent .form-select:focus,
.productsContent .form-select:hover {
  border: 0px !important;
  outline: 0 !important;
  box-shadow: none !important;
  cursor: pointer;
}

.productsContent .leftCol p,
.productsContent .leftCol .form-select {
  font-size: 18px;
}

.productsContent .leftCol p,
.productsContent .leftCol .inputDiv {
  padding: 0px 12px;
}

.productsContent .leftCol .inputDiv label {
  padding-right: 20px;
}

.productsContent .leftCol .inputDiv label,
.productsContent .leftCol .inputDiv input::placeholder {
  color: #c4c4c4;
}

.productsContent .leftCol {
  padding-left: 0px;
}

.leftColInnerDiv button {
  width: 50%;
  margin: 10px 15px;
}

.rightColInnerDiv img {
  width: 100%;
}

.productsListSpecificDivs p,
.productsListSpecificDivs h3,
.productsListSpecificDivs h5 {
  width: 50%;
  margin-bottom: 5px;
}

.productsListSpecificDivs Button {
  margin-top: 10px;
}

.productsListSpecificDivs .dateTextDiv {
  min-height: 55px;
}

.productsListSpecificDivs h3 {
  min-height: 45px;
}

/* .mainContent.productsContent .container-fluid{
padding: 0px;
}
.mainContent.productsContent .container-fluid .rightCol{
  padding: 0px;
} */
.mainContent.productsContent .containerFluid {
  padding: 0px;
}

.mainContent .mg-tb {
  margin: -60px 0px 50px;
}

.rightColInnerDiv h3,
.rightColInnerDiv h5 {
  font-size: 18px;
  font-weight: 700;
}

.rightColInnerDiv h3 {
  color: #980033;
  text-align: left;
  /* white-space: nowrap; */
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
  margin-bottom: 2px;
}

.rightColInnerDiv h5 {
  white-space: nowrap;
  /* width: 50px;  */
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightColInnerDiv p {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.productListRed {
  color: #980033;
  height: 20px;
}

.rightColInnerDiv .boxStyle {
  border-radius: 20% 10px 10px 10px;
  z-index: -1;
  padding-top: 30%;

  box-shadow: 0px 0px 7px 0px #1111112e;
}

.paddingSide p {
  margin-bottom: 10px;
}

.rightColInnerDiv .buttonDivStyle {
  border-radius: 0px 0px 10px 10px;
}

.rightColInnerDiv .boxStyle .paddingSide {
  padding: 20px 20px 10px;
}

.exploreProduct {
  background: #980033;
  align-items: center;
  height: 48px;
  background: #980033;
  width: 20%;
  border-radius: 5px;
  justify-content: center;
  margin: auto;
  display: flex;
  text-align: center;
  width: 219px;

  border-radius: 5px;
}

.fw-400 {
  font-weight: 400 !important;
}

.exploreProduct a {
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
}

.leftColInnerDiv {
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 0px 7px rgba(17, 17, 17, 0.18);
}

.leftColInnerDiv .card {
  border-radius: 0px;
  border: 0px;
}

.card .collapse.show > .card {
  background: #ccc;
}

.leftColInnerDiv .card-header {
  background: none;
  border-bottom: 0px;
  font-size: 18px;
  padding: 10px 12px;
}

.rightColInnerDiv {
  margin: 25px 0px 0px;
  margin-top: 0px;
  /* background: #ffffff;
  box-shadow: 0px 0px 7px rgba(17, 17, 17, 0.18); */
}

.fs-12 {
  font-size: 12px;
}

.buttonDivStyle {
  background: #212529;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
}

.productDetailsMainContent .buttonStyle button {
  width: auto;
  text-align: center;
  padding: 5px 25px;
  margin-right: 15px;
}

.productDetailsMainContent .buttonDivStyle {
  position: relative;
  /* padding-left: 40px; */
  text-align: center;
  display: flex;
  align-items: center;
}

.productDetailsMainContent .detailsContentTableDiv table,
.productDetailsMainContent .detailsContentTableDiv table td,
.productDetailsMainContent .detailsContentTableDiv table th {
  border: 0px;
}

.productDetailsMainContent .buttonDivStyle svg {
  /* position: absolute; */
  width: 25px;
  height: 25px;
  /* left: 8px; */
  margin-right: 5px;
}

.rightColInnerDiv .buttonDivStyle {
  padding: 10px;
  width: 100%;
}

.rightColInnerDiv .productImageDiv {
  position: relative;
  width: 100%;
  top: 75px;
  height: auto;
}

.rightColInnerDiv .productImageDiv img {
  height: 100%;
  width: 216px;
  padding: 6px;
  background: #ffffff;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  margin: auto;
  box-shadow: 0px 0px 7px rgb(17 17 17 / 18%);
  text-align: center;
  border-radius: 50%;
  height: 216px;
  object-fit: contain;
}

.rightColInnerDiv .productImageDiv p {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.pbottom40 {
  padding-bottom: 50px;
}

.filterProductsDiv .form-select,
.filterProductsDiv .form-select:hover,
.filterProductsDiv .form-select:focus {
  border: 0.5px solid #707070 !important;
  width: 65%;
}

.filterProductsDiv p {
  font-size: 23px;
  font-weight: 700;
  margin-bottom: 0px;
}

.filterProductsDiv label {
  font-size: 23px;
  font-weight: 400;
  margin-right: 20px;
  margin-bottom: 0px;
}

.filterProductsDiv Form {
  width: 25%;
}

.filterProductsDiv {
  margin-bottom: 25px;
}

.sliderDiv {
  margin-top: 10px;
}

.sliderDiv .slick-prev:before,
.sliderDiv .slick-next:before {
  color: #777;
}

.sliderDiv img {
  width: 150px;
  height: 100%;
}

.sliderDiv .slideImgDiv {
  padding: 0px;
}

.sliderDiv .card {
  flex-direction: initial;
}

.sliderDiv .card p {
  font-size: 12px;
  color: #5e5e5e;
  font-weight: 400;
  margin-bottom: 3px;
}

.sliderDiv .card .card-body .card-text {
  font-size: 20px;
  font-weight: 700;
  color: #212529;
  line-height: 25px;
}

.sliderDiv .slick-slide {
  padding: 5px;
}

.sliderDiv .slick-dots li button:before {
  font-size: 9px;
}

.sliderDiv .slick-dots li.slick-active button:before {
  font-size: 12px;
}

.sliderDiv .slick-dots li {
  margin: 0px 0px;
}

/*footer page*/
.footerContent {
  background: #212529;
  color: #fff;
}

.footerHrDiv {
  border: 8px solid #980033;
  width: 60%;
}

.footerContent h2 {
  padding: 0px 20px;
}

.footerContent ul {
  padding-left: 0px;
}

.footerContent ul li {
  list-style-type: none;
  padding: 10px 25px;
}

.footerContent ul li:last-child {
  background: #980033;
}

.footerContent .innerListDiv p {
  margin-bottom: 0px;
}

.footerContent .innerListDiv p span {
  padding: 0px 10px;
}

.footerContent .rightColFooterDiv {
  padding-top: 80px;
}

.footerContent .rightHrDiv {
  width: 15%;
  border-width: 5px;
  margin-left: 20px;
}

.footerBottomContent {
  /* padding: 15px; */
  padding: 15px 80px;
}

.footerBottomContent p,
.footerBottomContent ul {
  margin-bottom: 0px;
}

.footerBottomContent ul li {
  list-style-type: none;
  padding: 0px 15px;
}

.rightColFooterDiv .innerListDiv {
  padding-right: 150px;
}

/*modal css*/
.modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  max-width: auto;
}

.loginModalMainDiv {
  min-width: 85%;
}

.loginModalMainDiv .modal-content {
  background: url("./images/PopupBackground.png");
  background-size: cover;
  background-position: center;
  padding: 40px;
}

.loginModalDiv {
  text-align: center;
}

.loginModalDiv h1 {
  font-size: 44px;
  font-weight: 900;
  color: #212529;
}

.loginModalDiv h4 {
  font-size: 24px;
  color: #5e5e5e;
}

.loginModalDiv p {
  font-size: 18px;
  color: #212529;
}

.loginModalDiv hr {
  width: 50%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 5px;
}

.loginModalDiv input {
  text-align: center;
  width: 50%;
  display: table;
  margin: 0 auto;
}

.loginModalDiv Button {
  padding: 6px 40px;
  border-radius: 0px;
}

.signinpara {
  margin-top: 10px;
  font-size: 15px;
}

.productModalDiv .slick-prev,
.productModalDiv .slick-next {
  padding: 0px;
}

.productModalDiv .slick-next {
  right: -30px;
}

.productModalDiv .slick-prev {
  left: -30px;
}

.productModalDiv .modal-body,
.productModalDiv .slick-slider {
  padding: 0px;
}

.productModalDiv .modal-content {
  border: 0px;
  border-radius: 0px;
  background-color: transparent;
}

/***************/
table {
  border: 0.5px solid #c4c4c4;
  margin: 15px 0px;
}

thead {
  background: #f9f9f9;
}

th,
td {
  padding: 8px !important;
}

.buttonStyle button {
  width: 48%;
}

.highlightDiv {
  margin: 15px 0px;
  padding: 8px 12px;
  /* min-height: 200px; */
  background: #f9f9f9;
  border: 0.5px solid #707070;
}

.highlightDiv h3 {
  font-size: 24px;
}

.DetailspageSliderDiv .slick-slide img {
  width: 100%;
  margin: 0 auto;
  height: 200px;
  border: 0px;
  outline: 0 !important;
}

.detailsDots {
  position: relative !important;
  display: flex !important;
  bottom: 0 !important;
  justify-content: space-between;
  width: 80% !important;
  margin: 0 auto !important;
}

.detailsDots li {
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}

.detailsDots img {
  width: 100%;
  height: 100%;
}

.rightColMainHeader {
  padding-bottom: 10px;
}

.darkBg {
  background: #f9f9f9 !important;
}

.productsPaginationDiv {
  padding: 60px 0px 0px;
}

.productsPaginationDiv ul {
  padding-left: 0px;
}

.productsPaginationDiv ul li {
  list-style-type: none;
  padding: 0px 5px;
  color: #ccc;
}

.productsPaginationDiv ul li.active {
  color: #212529;
}

.productsPaginationDiv svg {
  margin: 0px 5px;
}

.productsPaginationDiv .svgOneDiv {
  transform: rotate(180deg);
}

.categorySliderDiv .slick-slide {
  /* display: flex !important; */
  width: 33.3% !important;
  /* justify-content: space-around;
  align-items: center; */
}

.categorySliderDiv .slick-track {
  width: 100% !important;
}

.categorySliderDiv .categoryImageSection {
  height: 150px;
  width: 100%;
}

.categorySliderDiv .slideImgDiv {
  width: 100%;
}

.categorySliderDiv .slideImgDiv img {
  width: auto;
  height: 100%;
  margin: 0 auto;
}

.categorySliderDiv .categoryImageSection .categoryNameDiv {
  bottom: -15px;
  left: -5px;
}

#home .middle-sec button {
  background: #02c8f4;
  border: none;
  font-size: 21px;
  padding: 10px 48px;
  font-weight: 500;
}

.productPaddingDiv {
  padding: 0px 12px;
}

.white-border {
  width: 120px;
  border-bottom: 6px solid #fff;
  margin: auto;
  text-align: center;
  padding: 10px 0px;
}

.categoryOrderListingDiv ul {
  padding-left: 0px;
}

.categoryOrderListingDiv ul li {
  list-style-type: none;
  position: relative;
  padding: 5px 5px;
  cursor: pointer;
}

.categoryOrderListingDiv ul li p {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* width: 100%; */
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: #fff;
  line-height: 24px;
  text-align: center;
}

.catlistinnerselectDiv {
  /* padding-top: 10px; */
  position: absolute;
  /* padding-left: 5%; */
}

.catlistinnerselectDiv .basic-single {
  width: 100%;
  margin-left: 15px;
}

.catlistinnerselectDiv label {
  font-size: 23px;
}

.catHeaderProductDiv {
  padding: 25px 0px;
  /* background: #f9f9f9; */
}

.container {
  padding: 0px;
}

.activeCatDiv {
  display: none;
}

.activeProductCatDiv .nonActiveCatDiv {
  display: none;
}

.activeProductCatDiv .activeCatDiv {
  display: block;
  /* width: 100%; */
}

.userFilterBtn {
  min-height: 41px;
  white-space: nowrap;
  border-radius: 4px;
  /* padding: 0px 25px; */
  font-size: 18px;
  background: #fff;
  outline: none !important;
  box-shadow: none !important;
  /* margin-left: 5px; */
}

.facebookWidth {
  width: 15px;
  height: 20px;
}
.DetailspageSliderDiv .slick-prev:before {
  content: url("./images/Path 14384.png");
}

.DetailspageSliderDiv .slick-prev {
  left: 2%;
  z-index: 99;
}

.DetailspageSliderDiv .slick-next:before {
  content: url("./images/Path 12308.png");
}

.DetailspageSliderDiv .slick-next {
  right: 2%;
}

.DetailspageSliderDiv .slick-prev,
.DetailspageSliderDiv .slick-next {
  background: #fff !important;
  padding: 7px;
  padding-right: 15px;
  height: auto;
}

/* productSliderPopupDiv */
.productSliderPopupDiv .slick-prev:before {
  content: url("./images/Path 14384.png");
}

.productSliderPopupDiv .slick-prev {
  left: 15px;
  z-index: 99;
}

.productSliderPopupDiv .slick-next:before {
  content: url("./images/Path 12308.png");
}

.productSliderPopupDiv .slick-next {
  right: 15px;
}

.productSliderPopupDiv .slick-prev,
.productSliderPopupDiv .slick-next {
  background: #fff !important;
  padding: 7px;
  padding-right: 15px;
  height: auto;
}

.privacyPolicy h3 {
  margin-bottom: 10px;
}

.privacyPolicy ul {
  margin-left: 15px;
}

.CatageryName {
  position: relative;
  top: -43%;
  color: white;
  padding: 0px 5px;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.fontRoboto723 {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 23px;
}

.fontRoboto523 {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-size: 23px;
}

/*********************/
@media screen and (max-width: 992px) {
  .userFilterBtn {
    font-size: 12px;
  }

  .catlistinnerselectDiv label {
    font-size: 16px;
    white-space: nowrap;
  }

  .rightColFooterDiv .innerListDiv {
    padding-right: 0px;
  }

  .footerContent ul li {
    padding: 5px 10px;
  }

  .mainHeaderDiv {
    justify-content: space-between;
  }

  .rightColMainHeader {
    width: 70%;
  }

  .leftInnerColDiv {
    width: 70%;
  }

  .rightInnerColDiv {
    width: 30%;
  }
}

@media screen and (max-width: 800px) {
  .CatageryName {
    font-size: 10px;
  }

  .catlistinnerselectDiv {
    padding-left: 0%;
  }

  .catlistinnerselectDiv .basic-single {
    width: 60%;
  }

  .footerContent ul li {
    padding: 5px 7px;
  }
}

@media screen and (max-width: 768px) {
  .DetailspageSliderDiv .slick-prev {
    background: none !important;
    left: -49%;
  }
  .DetailspageSliderDiv .slick-next {
    background: none !important;
    right: -51%;
  }
  .mainHeaderDiv .rightColMainHeader ul {
    justify-content: center !important;
  }

  .homeLoginDivCss .homeLogRight {
    position: inherit;
    padding: 40px 20px;
    height: auto;
    width: 85%;
    margin: auto;
  }

  .formBackimg .homeLoginDivCss .homeLogLeft h4 {
    font-size: 20px;
    line-height: 23px;
  }

  .formBackimg .homeLoginDivCss .homeLogLeft i {
    font-size: 18px;
    line-height: 21px;
  }

  .formBackimg .homeLoginDivCss .homeLogLeft p {
    font-size: 15px;
    line-height: 18px;
  }

  .container {
    padding: 0px 15px;
  }

  .detailspagerow {
    display: block !important;
  }

  .detailspagerow .detailspageCol {
    width: 100%;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) {
  .detailspageCol .buttonStyle {
    display: block !important;
  }

  .detailspageCol button {
    width: 100%;
    margin-bottom: 25px;
  }

  .detailspageCol button:last-child {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .productDetailsMainContent .buttonDivStyle {
    margin-bottom: 25px;
  }

  .sliderDiv .card,
  .mainHeaderDiv,
  .mainHeaderDiv .innerHeaderRow {
    display: block !important;
  }

  .sliderDiv .card img,
  .footerContent .leftColFooterDiv,
  .footerContent .rightColFooterDiv,
  .mainHeaderDiv .innerHeaderRow .leftInnerColDiv,
  .mainHeaderDiv .innerHeaderRow .rightInnerColDiv,
  .mainHeaderDiv .leftColMainHeader,
  .mainHeaderDiv .rightColMainHeader,
  .footerContent .rightHrDiv {
    width: 100%;
  }

  .innerHeaderRow {
    margin: 0 auto;
  }

  .footerContent .rightColFooterDiv {
    padding-top: 0;
    text-align: center;
    justify-content: center !important;
  }

  .mainHeaderDiv .leftColMainHeader .navbar-brand {
    display: table;
  }

  .mainHeaderDiv .rightColMainHeader input {
    text-align: center;
    margin-bottom: 25px;
  }

  .footerContent .rightHrDiv,
  .footerContent .leftColFooterDiv .footerHrDiv,
  .mainHeaderDiv .leftColMainHeader .navbar-brand {
    margin: 0 auto;
    margin-bottom: 15px;
  }

  .footerContent .leftColFooterDiv .footerHrDiv {
    margin-top: 15px;
  }

  .footerContent .rightColFooterDiv ul,
  .mainHeaderDiv {
    justify-content: center !important;
  }

  .rightColFooterDiv .innerListDiv {
    padding-right: 0px;
  }

  .footerBottomContent {
    padding: 10px 15px;
  }

  .footerContent .leftColFooterDiv {
    text-align: center;
  }

  .filterProductsDiv Form {
    width: 60%;
  }

  .productModalDiv {
    max-width: 80%;
    margin-left: 0px;
  }

  .productModalDiv .slick-next {
    right: -10px;
  }

  .productModalDiv .slick-prev {
    left: -10px;
    z-index: 999;
  }
}

@media screen and (max-width: 640px) {
  .CarouseDivTwoContainer {
    padding-bottom: 0px !important;
  }
  .slick-dots {
    position: absolute !important;
    bottom: 0px !important;
    top: 124px;
}
  .DetailspageSliderDiv .slick-prev {
    background: none !important;
    left: -49%;
  }
  .DetailspageSliderDiv .slick-next {
    background: none !important;
    right: -51%;
  }
  .homeLoginDivCss {
    display: block;
  }

  .catlistinnerselectDiv {
    justify-content: start !important;
  }

  .catlistinnerselectDiv .basic-single {
    width: 70%;
  }

  #header {
    padding: 0px 20px;
  }

  .rightColInnerDiv .boxStyle {
    padding-top: 20%;
  }

  .pbottom40 {
    padding-bottom: 0px;
  }

  /* .homeLoginDivCss .homeLogLeft,
  .homeLoginDivCss .homeLogRight {
    width: 100%;
  } */

  .homeLoginDivCss .homeLogLeft {
    padding: 40px;
  }

  .footerContent ul li {
    padding: 10px 10px;
  }

  .footerContent .rightColFooterDiv ul {
    display: block !important;
    margin-bottom: 25px;
  }

  .filterProductsDiv {
    display: block !important;
  }

  .filterProductsDiv p {
    margin-bottom: 15px;
  }

  .productsContent .leftColInnerDiv {
    margin-bottom: 25px;
  }

  .categoryOrderListingDiv ul {
    display: block !important;
  }

  .categoryOrderListingDiv ul li {
    display: inline-flex;
    width: 50%;
  }

  .categoryOrderListingDiv ul li img.nonActiveCatDiv {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .CatageryName {
    font-size: 14px;
  }

  .catlistinnerselectDiv {
    padding-top: 10px;
    padding-left: 0%;
  }

  .catlistinnerselectDiv .basic-single {
    width: 200%;
  }

  .wideVariety {
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .DetailspageSliderDiv .slick-prev {
    background: none !important;
    left: -45%;
  }
  .DetailspageSliderDiv .slick-next {
    background: none !important;
    right: -46%;
  }
}
@media screen and (max-width: 480px) {
  /* .CarouseDivTwoContainer .slick-track {
    left: -97% !important;
  } */

  .DetailspageSliderDiv .slick-prev {
    background: none !important;
    left: -45%;
  }
  .DetailspageSliderDiv .slick-next {
    background: none !important;
    right: -46%;
  }
  .CarouseDivTwo .card-body {
    border-right: 0px;
  }

  .wideVariety {
    font-size: 20px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.d-flex.align-items-stretch.card {
  border: none;
  background: transparent;
  /* border-right: gray solid; */
  border-radius: 0%;
}

.card-body-ticker.card-body {
  padding: 5px 3px 10px 5px;
  border: 1px solid #efefef;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
}

/* ashwini code */
/* ashwini code */
.skudetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  padding: 10px 17px 10px 17px;
}
.mainskuheading {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 22px;
  color: #980033;
  margin-bottom: 0px;
}
.skuspan {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #5e5e5e;
}
.skuinnerdetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 17px 0px 10px;
}
.innerpara {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 29px;
  color: #5e5e5e !important;
}
.mycard {
  padding: 0px !important;
}
@media (min-width: 360px) and (max-width: 740px) {
  .skuspan {
    font-size: 17px;
  }
  .mainskuheading {
    font-size: 20px;
  }
}
@media (max-width: 480px) {
  .navbar ul {
    height: 30px;
  }
  .skuspan {
    font-size: 15px;
  }
}
@media (max-width: 508px) {
  .skuspan {
    font-size: 15px;
  }
}
.freshrproducts {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 641px) and (max-width: 992px) {
  .resfix {
    width: 100%;
  }
  .activeProductCatDiv .activeCatDiv {
    width: 100%;
  }
}
@media (min-width: 280px) and (max-width: 640px) {
  .activeProductCatDiv .activeCatDiv {
    width: 100%;
  }
}
.hamburger-menu {
  display: none;
}
.mobile-menu {
  display: none;
}

@media (max-width: 991.98px) {
  .hamburger-menu {
    display: block;
    padding-right: 20px;
  }
  .resmenu {
    font-size: 30px;
    color: #8b002f;
  }
  .mobile-nav {
    margin: 1rem 0;
    display: grid;
    width: 100%;
    padding: 20px;
  }
  .active {
    top: 80px;
  }
  .mobile-menu {
    position: relative;
    display: block;
    top: 130px;
    /* align-items: center; */
    transition: 0.3s;
    background-color: #f5f5f5;
  }
  .mobile-menu ul {
    list-style: none;
  }
  .toolTip-Menu {
    position: absolute;
    top: 65px !important;
    font-size: 11px;
    font-weight: 600;
    padding: 1px 5px;
    color: rgb(255, 255, 255);
    border-radius: 7px;
    background: rgb(152, 0, 51);
  }
}
.headerMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.prodcat {
  flex-wrap: wrap;
}
@media (min-width: 768px) and (max-width: 880px) {
  .sliderlogo {
    width: 50%;
  }
}

/* css */
.catlistinnerselectDiv .select__value-container{
  width: 100px;
}
/* mobile break points */
@media  (min-width :576px ) and (max-width: 800px) {
  .catlistinnerselectDiv .select__control {
    width: 113px;
  }
 
}
@media  (min-width :576px ) and (max-width: 620px) {
  .catlistinnerselectDiv {
    position: relative;
    left: -49%;
  }
}
